<template>
    <div>
        <v-container>
            <v-card style="background-color: #F5F5F5" elevation="3">
                <div class="mt-2 mb-5 text-h6 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
                    Detalle de solicitud de material
                </div>

                <v-row v-if="isResp">
                    <v-col class="px-10 py-0 mt-4 mb-n5" cols="12" sm="6" md="5" lg="4" xl="4">
                        <v-text-field v-model="sol.Per.PerNom"
                            label="Empleado"
                            readonly
                            dense
                            outlined>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="px-10 py-0 mt-2 mb-n5" cols="12" sm="7" md="6" lg="5" xl="4">
                        <v-text-field v-model="sol.Mat.MatNom"
                            label="Material"
                            readonly
                            dense
                            outlined>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="sol.MatVar1 != null">
                    <v-col class="px-10 py-0 mt-2 mb-n5" cols="10" sm="5" md="4" lg="3" xl="2">
                        <v-text-field 
                            v-model="sol.MatVar1.MatVarNom"
                            :label="MatVar1Nom" 
                            readonly
                            dense
                            outlined>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="sol.MatVar2 != null">
                    <v-col class="px-10 py-0 mt-2 mb-n5" cols="10" sm="5" md="4" lg="3" xl="2">
                        <v-text-field 
                            v-model="sol.MatVar2.MatVarNom"
                            :label="MatVar2Nom"
                            readonly
                            dense
                            outlined>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="px-10 py-0 mt-2 mb-n5" cols="12" sm="8" md="6" lg="5" xl="4">
                        <v-text-field v-model="sol.Dir.DirNom"
                            label="Dirección de entrega"
                            readonly
                            dense
                            outlined>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="px-10 py-0 mt-2 mb-n5" cols="12" sm="4" md="3" lg="2" xl="2">
                        <v-text-field v-model="sol.SolCant"
                            label="Cantidad"
                            outlined
                            dense
                            readonly>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="px-10 py-0 mt-2 mb-n5" cols="8" sm="5" md="4" lg="4" xl="3">
                        <v-text-field v-model="formatedSolDate"
                            label="Fecha de solicitud"
                            readonly
                            dense
                            outlined>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="px-10 py-0 mt-2 mb-n5" cols="12" sm="8" md="6" lg="6" xl="4">
                        <v-textarea v-model="sol.SolObs"
                            label="Observaciones"
                            readonly
                            outlined
                            auto-grow>
                        </v-textarea>
                    </v-col>
                </v-row>

                <v-row class="ml-4">
                    <v-col class="mt-2" cols="12" sm="10" md="9" lg="8" xl="7">
                        <v-data-table
                            :headers="headers"
                            :items="dataRows"
                            :options.sync="options"
                            :footer-props="{itemsPerPageOptions: [100]}"
                            :server-items-length="totalDataRows"
                            :loading="loading"
                            style="overflow: scroll"
                            mobile-breakpoint="0"
                            class="elevation-1"
                            dense>
                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                            <template v-slot:item.SolSta.SolStaNom="{item}">
                                <v-chip
                                :color="statusColor(item.SolStaId)"
                                small
                                style="min-width:85px"
                                class="status-chip">
                                    {{item.SolSta.SolStaNom}}
                                </v-chip>
                            </template>
                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                            <template v-slot:item.SolStaFec="{ item }">
                                {{ formatDate(item.SolStaFec) }}
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>

                <v-row justify="center">
                    <v-btn
                        :to="isResp ? '/solicitud-material-responsable' : '/solicitud-material'"
                        class="mt-2 mb-4"
                        style="width:100px">
                        Salir
                    </v-btn>
                </v-row>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default ({
    // components: {
    // },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz']),
        MatVar1Nom: function () {
            if (this.sol.Mat == null || this.sol.Mat.MatVar1 == null) { return 'Variante 1'; }
            return 'Variante - ' + this.sol.Mat.MatVar1.MatVarNom;
        },
        MatVar2Nom: function () {
            if (this.sol.Mat == null || this.sol.Mat.MatVar2 == null) { return 'Variante 2'; }
            return 'Variante - ' + this.sol.Mat.MatVar2.MatVarNom;
        }
    },
    data () {
        return {
            loading: false,
            isResp: false,
            statusRefs: {
              Pendiente: 10,
              Aceptado: 20,
              Rechazado: 30,
              EnTramite: 40,
              Enviado: 50,
              RecibidoConforme: 60,
              RecibidoNoConforme: 70,
              Devuelto: 110,
              DevueltoConforme: 120,
              DevueltoNoConforme: 130
            },
            sol: {
                SolId: null,
                Dir: {},
                Mat: {},
                Per: {},
                SolCant: null,
                SolObs: ''
            },
            formatedSolDate: '',
            totalDataRows: 0,
            dataRows: [],
            options: {itemsPerPage: 100},
            headers: [
                { text: 'Estado', width: 100, sortable: false, value: 'SolSta.SolStaNom' },
                { text: 'Fecha estado', width: 150, sortable: false, value: 'SolStaFec' },
                { text: 'Responsable', width: 250, sortable: false, value: 'Per.PerNom' },
            ]
        }
    },
    // watch: {
    // },
    mounted() {
        this.isResp = this.$route.meta.isResp;
        this.sol.SolId = this.$route.params.id;
        this.getDataFromApi()
            .then(data => {
            this.sol = data.item;
            this.formatedSolDate = this.formatDate(this.sol.SolFec);
            this.dataRows = data.itemStaList;
            this.totalDataRows = data.itemStaList.lenght;
        });
    },
    methods: {
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_SOL',
            SolId: this.sol.SolId,
            PerId: this.perId,
            IsResp: this.isResp,
            IncludeSolSta: true
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/sol", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Sol;
            const itemStaList = result.data.SolStaSolList;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item,
                    itemStaList
                })
            }, 1000)
          }).catch(error => {
            if(error != null) {
                this.loading = false;
                alert('Error al cargar la solicitud.');
            }
          });
        })
      },
      statusColor(status) {
        if(status === this.statusRefs.Aceptado) { return 'green'; }
        if(status === this.statusRefs.Rechazado) { return 'red'; }
        if(status === this.statusRefs.EnTramite) { return 'green'; }
        if(status === this.statusRefs.Enviado) { return 'green'; }
        if(status === this.statusRefs.RecibidoConforme) { return 'green'; }
        if(status === this.statusRefs.RecibidoNoConforme) { return 'yellow'; }
        if(status === this.statusRefs.DevueltoConforme) { return 'green'; }
        if(status === this.statusRefs.DevueltoNoConforme) { return 'red'; }
        return '';
      },
      formatDate(value) {
        if(value == null) { return ''; }
        const date = new Date(value);
        return String(date.getDate()).padStart(2, '0') + '/' + 
            String(date.getMonth() + 1).padStart(2, '0')  + '/' + 
            date.getFullYear() + ' ' +
            String(date.getHours()).padStart(2, '0') + ':' + 
            String(date.getMinutes()).padStart(2, '0');
      }
    } 
})
</script>

<style>
  .status-chip .v-chip__content {
    margin: auto;
  }
</style>
